.root {
  display: flex;
  margin-top: 10px;
  padding-bottom: 20px;
  margin-right: 20px;
  margin-left: 17px;
}

.avatar {
  margin-right: 15px;
}

.form {
  width: 100%;

  button {
    margin-top: 10px;
  }
}
